import React from 'react'
import SEO from '../components/SEO/SEO'
import CollectionsGrid from '../components/CollectionsGrid/CollectionsGrid'
// import PageTitle from '../components/PageTitle/PageTitle'

export default function IndexPage() {
  return (
    <>
      <SEO title="All collections" />
      {/* <PageTitle preTitle="Gallerie d'art" title="Les collections" /> */}
      <CollectionsGrid />
    </>
  )
}
