import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './CollectionsGrid.module.scss'
import Link from '../Link/Link'
import BGImg from 'gatsby-background-image'

export default function CollectionsGrid() {
  const data = useStaticQuery(graphql`
    query {
      collections: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/src/content/collections/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              image {
                childImageSharp {
                  id
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fields {
              collectionName
              slug
            }
          }
        }
      }
      order: file(relativePath: { eq: "settings/collections-list-order.md" }) {
        childMarkdownRemark {
          frontmatter {
            collectionsList {
              collectionName
            }
          }
        }
      }
    }
  `)

  const order = data.order.childMarkdownRemark.frontmatter.collectionsList
  const collections = data.collections.edges

  return (
    <div className={styles.collectionsGrid}>
      {order.length && collections.length
        ? order.map((item, index) => {
            return collections.map(collection => {
              if (
                item.collectionName === collection.node.fields.collectionName
              ) {
                const {
                  node: {
                    frontmatter: {
                      title,
                      image: {
                        childImageSharp: { id, fluid },
                      },
                    },
                    fields: { collectionName, slug },
                  },
                } = collection
                return (
                  <Link
                    className={styles.collectionLink}
                    to={slug}
                    key={`${id}-${index}`}
                  >
                    <BGImg
                      className={[
                        styles.collectionImage,
                        `atIndex${index}`,
                      ].join(' ')}
                      fluid={fluid}
                      title={title}
                      alt={title}
                    />
                    <h2 className={styles.collectionTitle}>{collectionName}</h2>
                  </Link>
                )
              } else {
                return null
              }
            })
          })
        : null}
    </div>
  )
}
